// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Thoughts from './Thoughts';
import Notes from './Notes';
import Topics from './Topics';
import Methods from './Methods';
import Table from './Table';
import RedFlags from './RedFlags';
import ImportantThings from './ImportantThings';
import SideEffectsBenefits from './SideEffectsBenefits';
import EmailModal from '../../shared/emailModal/EmailModal';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import summaryDataMapper from './summary_data_mapper';
import EmailIcon from './email-icon.svg';
import MenuIcon from './menu-icon.svg';
import RestartIcon from './restart-icon.svg';
import MyBcLogo from './MyBirthControl.png';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import { validEmail } from '../../../lib/utils';
import type { PageProps, TableData } from '../../../lib/types';
import printIcon from './print-icon.png';
import './Summary.scss';

type SummaryProps = PageProps & {
  notes: [string],
  topics: Array<string>,
  methodsToDiscuss: Object,
  thoughtsOnPregnancy: Object,
  importantThings: Object,
  redFlags: Object,
  sideEffectsBenefits: Object,
  checkTables: Array<TableData>,
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

type SummaryState = {
  isModalOn: boolean,
  isSendEmailButtonDisabled: boolean,
  isShowingEmailSuccessMessage: boolean,
  isShowingEmailErrorMessage: boolean,
  email: string,
};

const isMyPath = (/mypath/g).test(process.env.REACT_APP_MODE) || process.env.REACT_APP_MODE === 'superDev';
const isMyBC = process.env.REACT_APP_MODE === 'mybc';
const isPeripartum = process.env.REACT_APP_MODE === 'peripartum';
const mainMenuRoute = isMyPath ? 'main_nav_mp' : 'main_nav';

class Summary extends React.Component<SummaryProps, SummaryState> {
  _emailButton: HTMLButtonElement;
  _modalBody: HTMLParagraphElement;

  state = {
    isModalOn: false,
    isSendEmailButtonDisabled: true,
    isShowingEmailSuccessMessage: false,
    isShowingEmailErrorMessage: false,
    email: ''
  };

  emailButtonRef = (c: HTMLButtonElement) => { this._emailButton = c; }
  modalBodyRef = (c: HTMLButtonElement) => { this._modalBody = c; }

  printClickHandler = () => {
    try {
      setTimeout(window.print, 1000);
    } catch (e1) {
      try {
        document.execCommand('print', false, null);
      } catch (e2) {
        alert(`neither Javascript print function worked; ${e1}; ${e2}`);
      }
    }
  }

  emailInputHandler = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    this.setState({
      email: event.target.value,
      isSendEmailButtonDisabled: !validEmail(event.target.value)
    });
  };

  modalClickHandler = () => {
    const { setModalInvisible, setModalVisible } = this.props;
    const { isModalOn } = this.state;
    if (isModalOn) {
      setModalInvisible();
      this.setState({
        isModalOn: false,
        isShowingEmailSuccessMessage: false,
        isShowingEmailErrorMessage: false
      }, () => { this._emailButton.focus(); });
    } else {
      setModalVisible();
      this.setState({
        isModalOn: true,
        isShowingEmailSuccessMessage: false,
        isShowingEmailErrorMessage: false
      }, () => { this._modalBody.focus(); });
    }
  };

  emailSummary = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const { locale, content, dispatch, navLinks, isAnyModalVisible, ...data } = this.props;
    const { email } = this.state;
    this.setState({
      isSendEmailButtonDisabled: true,
      isShowingEmailSuccessMessage: false,
      isShowingEmailErrorMessage: false
    });

    fetch('/summary/email_summary', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({ data, email, locale })
    })
      .then((response: string): Object => response.json())
      .then(() => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailSuccessMessage: true
        });
      })
      .catch((err: string) => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailErrorMessage: true
        });

        console.warn(err);
      });
  };

  render() {
    const {
      content,
      notes,
      topics,
      locale,
      methodsToDiscuss,
      thoughtsOnPregnancy,
      importantThings,
      redFlags,
      sideEffectsBenefits,
      checkTables,
      userId,
      isAnyModalVisible,
    } = this.props;

    const {
      isModalOn,
      isSendEmailButtonDisabled,
      isShowingEmailSuccessMessage,
      isShowingEmailErrorMessage,
      email
    } = this.state;

    const myPathBcLocked = isMyPath && !thoughtsOnPregnancy.rows[thoughtsOnPregnancy.rows.length - 1].value;

    // console.log("isMyPath= ", isMyPath);

    return (
      <>
        {
          !isMyPath && (
            <>
              <button
                className="regular ribbon print-button"
                type="button"
                onClick={this.printClickHandler}
              >
                { content.print }
              </button>
              <img src={MyBcLogo} className="print-only mybc-logo" alt="" />
            </>
          )
        }
        <div className="summary-heading">
          <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
          {
            !isMyPath && <div className="user-id">Id: {userId}</div>
          }
        </div>
        <div className="description" aria-hidden={isAnyModalVisible}>{content.description}</div>

        {
          !isMyPath && (
            <button
              className="mobile ribbon print-button"
              type="button"
              onClick={this.printClickHandler}
            >
              <img src={printIcon} alt="" />
              <p>
                { content.print }
              </p>
            </button>
          )
        }

        <div className="summary-container" aria-hidden={isAnyModalVisible}>
          {isMyPath && (
            <Thoughts
              title={thoughtsOnPregnancy.title}
              rows={thoughtsOnPregnancy.rows}
            />
          )}

          {isMyPath && (
            <Topics
              topics={topics.rows}
              title={topics.title}
              emptyText={topics.emptyText}
            />
          )}

          {(isMyBC || isPeripartum) && (
            <div className="item when-pregnant">
              <h2>{thoughtsOnPregnancy.rows[2].category}</h2>
              <p>» {thoughtsOnPregnancy.rows[2].value}</p>
            </div>
          )}

          <Notes
            rows={notes.rows}
            title={notes.title}
            emptyNotesCopy={notes.emptyText}
          />

          {
            myPathBcLocked ? (
              <div className="bc-placeholder">
                <MarkdownBlock str={content.bcPlaceholder} />
              </div>
            ) : (
              <>
                <Methods
                  methods={methodsToDiscuss.methods}
                  title={methodsToDiscuss.title}
                  noMethods={methodsToDiscuss.noMethods}
                  methodNames={methodsToDiscuss.methodNames}
                />
                <div className="flex-container">
                  <ImportantThings
                    title={importantThings.title}
                    rows={importantThings.rows}
                  />
                  <RedFlags
                    title={redFlags.title}
                    emptyText={redFlags.emptyText}
                    redFlags={redFlags.rows}
                  />
                </div>
                <SideEffectsBenefits
                  title={sideEffectsBenefits.title}
                  rows={sideEffectsBenefits.rows}
                />
                {checkTables.map(
                  ({ caption, headings, rows }: object, index: number): React.Element<Table> => (
                    <Table
                      key={caption}
                      caption={caption}
                      headings={headings}
                      rows={rows}
                      noneText={index === 0 && content.pastMethodsNone}
                    />
                  )
                )}
              </>
            )
          }
        </div>
        <div
          className="summary-button-container"
          style={isAnyModalVisible ? { display: 'none' } : null}
        >
          <Link
            to={`/${locale}/${mainMenuRoute}`}
            aria-label={content.mainMenuButton}
            id="summary-main-menu"
          >
            <button type="button" className={`summary-button main-menu ${isAnyModalVisible ? 'no-display' : ''}`}>
              <img src={MenuIcon} alt="" aria-hidden />
              <p>{content.mainMenuButton}</p>
            </button>
          </Link>
          <a
            href="/"
            aria-label={content.restartButton}
            id="summary-restart"
          >
            <button type="button" className="summary-button restart">
              <img src={RestartIcon} alt="" aria-hidden />
              <p>{content.restartButton}</p>
            </button>
          </a>
          <button
            className="summary-button email"
            type="button"
            aira-label={content.emailButton}
            onClick={this.modalClickHandler}
            ref={this.emailButtonRef}
            tabIndex={isAnyModalVisible ? null : '0'}
          >
            <img src={EmailIcon} alt="" aria-hidden />
            <p>{content.emailButton}</p>
          </button>
        </div>
        <EmailModal
          closeModalHandler={this.modalClickHandler}
          isModalOn={isModalOn}
          submitHandler={this.emailSummary}
          emailInputHandler={this.emailInputHandler}
          email={email}
          isShowingEmailErrorMessage={isShowingEmailErrorMessage}
          isShowingEmailSuccessMessage={isShowingEmailSuccessMessage}
          emailModalText={content.emailModal}
          isSendEmailButtonDisabled={isSendEmailButtonDisabled}
          closeBox={content.closeBox}
          modalHeadingRef={this.modalBodyRef}
        />
      </>
    );
  }
}

const mapStateToProps = (state: Object, ownProps: Object): Object => ({
  userId: state.userId,
  ...summaryDataMapper({
    state,
    content: ownProps.content,
  })
});

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
