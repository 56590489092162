// @flow

import { combineReducers } from 'redux';
import visitedStateMBC from './visitedReducerMBC';
import visitedStateMP from './visitedReducerMP';
import notesReducer from './notes_reducer';
import topicReducer from './topic_reducer';
import fertilityMenuReducer from './fertility_menu_reducer';
import healthPregnancyReducer from './health_pregnancy_reducer';
import sideEffectsCategoryVisitedArr from './sideEffectsCategoryVisitedReducer';
import surveyStateMP from './surveyReducerMP';
import surveyStateMBC from './surveyReducerMBC';
import activeRecs from './activeRecsReducer';
import currentMethods from './currentMethodsReducer';
import isAnyModalVisible from './isAnyModalVisibleReducer';
import afterBabyTopics from './afterBabyReducer';
import isShowingRecs from './showRecsReducer';
import userId from './userIdReducer';
import isShowingTopicModal from './topicModalReducer';
import isShowingSummaryReminder from './summaryReminderReducerMP';
import type { State, Action } from '../lib/types';

type Reducer = Action => State;

const mainReducer = {
  visitedStateMBC,
  visitedStateMP,
  notesReducer,
  topicReducer,
  fertilityMenuReducer,
  healthPregnancyReducer,
  sideEffectsCategoryVisitedArr,
  surveyStateMP,
  surveyStateMBC,
  activeRecs,
  currentMethods,
  isAnyModalVisible,
  afterBabyTopics,
  isShowingRecs,
  userId,
  isShowingTopicModal,
  isShowingSummaryReminder,
};

const rootReducer = (state: State, action: {}): Reducer => {
  let newState = Object.assign({}, state);
  if (action.type === 'RESET_APP') newState = undefined;

  return combineReducers(mainReducer)(newState, action);
};

export default rootReducer;
