// @flow

import type { Action } from '../lib/types';

export default {
  mainNavVisitMBC: (value: number): Action => ({
    type: 'VISIT_MAIN_NAV',
    value,
  }),
  mainNavVisitMP: (value: number): Action => ({
    type: 'VISIT_MAIN_NAV_MP',
    value,
  }),
  showAllMainNavMP: (): { type: string } => ({ type: 'MAIN_NAV_MP_ALL' }),
  secretVisitAllMBC: (): { type: string } => ({ type: 'PREVISIT_ALL_MBC' }),
};
