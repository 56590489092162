// @flow

import * as React from 'react';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import ifvisible from 'ifvisible';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';
import { persistor, store } from '../lib/store';
import Selector from './selector';
import './app.scss';
import InactivityModal from './shared/InactivityModal';

require('dotenv').config();

const history = createBrowserHistory();

const importAll = (r: Object): Array<string> => r.keys().map(r);

if (process.env.NODE_ENV !== 'production') Sentry.init({ dsn: 'https://3949c9b0cabf4015ab797d096105edd9@sentry.io/1419156' });

// add/remove .using-mouse class based on user input via keyboard/mouse
// this class is used to manage the viability of :focus outline
document.body.addEventListener('mousedown', () => { document.body.classList.add('using-mouse'); });
document.body.addEventListener('keydown', () => { document.body.classList.remove('using-mouse'); });

class App extends React.Component<*> {
  state = { isInactivityModalOn: false }

  componentDidMount() {
    if (window.location.pathname === '/') persistor.purge();
    // if there is no userID in localstorage via Redux-persist
    if (!store.getState().userId) {
      // get one from server
      this.fetchNewUser();
    }

    // image preloading
    const imageArr = importAll(require.context('.', true, /\.(png|jpe?g|svg)$/));
    setTimeout(() => {
      imageArr.forEach((imageSrc: string) => {
        const preloadImage = new Image();
        preloadImage.src = imageSrc;
      });
    }, 1000);

    if (navigator.standalone && !(/mypath/g).test(process.env.REACT_APP_MODE)) {
      this.minimizeCheckInterval = setInterval(() => {
        const { pathname } = window.location;
        if (ifvisible.now('hidden') && pathname !== '/') {
          // 4 steps needed to reset app
          localStorage.clear();
          store.dispatch({ type: 'RESET_APP' });
          history.push('/');
          this.fetchNewUser();
        }
      }, 1000);
    }
  }

  fetchNewUser = () => {
    fetch('/users', { method: 'POST' }).then((res: string): {} => res.json())
      .then((json: {}) => {
        store.dispatch({
          type: 'SET_USER_ID',
          value: json.id,
        });
      }).catch(err => console.log(err));
  }

  onIdle = () => {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (window.location.pathname !== '/' && !(/mypath/g).test(process.env.REACT_APP_MODE) && windowWidth > 719) {
      this.setState({ isInactivityModalOn: true });
    }
  }

  onActive = () => { if (!(/mypath/g).test(process.env.REACT_APP_MODE)) this.setState({ isInactivityModalOn: false }); }

  onInactivityModalClick = () => { this.setState({ isInactivityModalOn: false }); }

  render() {
    const { isInactivityModalOn } = this.state;

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {
            !(/mypath/g).test(process.env.REACT_APP_MODE) && (
              <IdleTimer
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                debounce={250}
                timeout={1000 * 60 * 2}
              />
            )
          }
          {isInactivityModalOn && <InactivityModal clickHandler={this.onInactivityModalClick} handleTurningOffModal={this.onActive} />}
          <Router history={history}>
            <Route render={(props: Object): React.Element<typeof Selector> => <Selector {...props} />} />
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
