import HelloBeautiful from './components/mybc/splashMBC/Splash';
import BigDeal from './components/mybc/introMBC/Intro';
import Effectiveness from './components/mybc/effectiveness/Effectiveness';
import MainMBC from './components/mybc/MainMBC/Main';
import IUDinfo from './components/mybc/IUDinfo/IUDinfo';
import HowToWomen from './components/mybc/howToWomen/HowToWomen';
import HowToMen from './components/mybc/howToMen/HowToMen';
import EmergencyContraception from './components/mybc/emergencyContraception/EmergencyContraception';
import FertilityAwareness from './components/mybc/fertilityAwareness/FertilityAwareness';
import HowOften from './components/mybc/howOften/HowOften';
import SideEffectPreface from './components/mybc/sideEffectPreface/SideEffectPreface';
import SideEffects from './components/mybc/sideEffects/SideEffects';
import SideEffectPerMethod from './components/mybc/sideEffectsPerMethod/SideEffectsPerMethod';
import FertilityFuture from './components/mybc/fertilityFuture/FertilityFuture';
import SplashMP from './components/mypath/SplashMP/SplashMP';
import IntroMP from './components/mypath/IntroMP/IntroMP';
import MainMP from './components/mypath/MainMP/MainMP';
import SurveyMP from './components/mypath/SurveyMP/SurveyMP';
import Survey1MBC from './components/mybc/surveyMBC/Survey1MBC';
import Survey2MBC from './components/mybc/surveyMBC/Survey2MBC';
import Survey3MBC from './components/mybc/surveyMBC/Survey3MBC';
import Survey4MBC from './components/mybc/surveyMBC/Survey4MBC';
import Survey5MBC from './components/mybc/surveyMBC/Survey5MBC';
import CurrentMethods from './components/mypath/currentMethods/CurrentMethods';
import FertilityMenu from './components/mypath/fertility/FertilityMenu';
import HealthPregnancyMenu from './components/mypath/health/HealthPregnancyMenu';
import HealthPregnancyToipcs from './components/mypath/health/HealthPregnancyTopics';
import CurrentMethodsSurvey from './components/mypath/currentMethodsSurvey/CurrentMethodsSurvey';
import Recommendations from './components/mybc/recommendations/Recommendations';
import CompareMethods from './components/mybc/compareMethods/CompareMethods';
import Summary from './components/mybc/summary/Summary';
import SecretDeveloperPage from './components/shared/SecretDeveloperPage';
import SexAfterBaby from './components/peripartum/SexAfterBaby/SexAfterBaby';
import TakeAMoment from './components/peripartum/IntroPeri/IntroPeripartum';
import PregnantAgainFirst from './components/peripartum/PregnantAgain/PregnantAgainFirst';
import PregnantAgainSecond from './components/peripartum/PregnantAgain/PregnantAgainSecond';
import Breastfeeding from './components/peripartum/Breastfeeding/Breastfeeding';
import ImportantInfo from './components/peripartum/ImportantInformation/ImportantInformation';
import Considerations from './components/peripartum/Considerations/Considerations';
import GotQuestions from './components/mybc/gotQuestions/GotQuestions';

const sharedRoutes = {
  effectiveness: {
    component: Effectiveness,
    additionalClasses: 'icon_array_page',
    back: 'main_nav',
    next: 'iud_info',
  },
  iud_info: {
    component: IUDinfo,
    next: 'main_nav',
    back: 'effectiveness',
  },
  side_effect_preface: {
    component: SideEffectPreface,
    back: 'main_nav',
    next: 'side_effects',
  },
  side_effects_per_method: {
    component: SideEffectPerMethod,
    back: 'side_effects',
    next: 'main_nav',
  },
  how_to_women: {
    component: HowToWomen,
    additionalClasses: 'how_to_page',
    next: 'how_to_men',
    back: 'main_nav',
  },
  how_to_men: {
    component: HowToMen,
    additionalClasses: 'how_to_page',
    back: 'how_to_women',
    next: 'emergency_contraception',
  },
  // emergency_contraception: {
  //   component: EmergencyContraception,
  //   back: 'how_to_men',
  //   next: 'fertility_awareness',
  // },
  // fertility_awareness: {
  //   component: FertilityAwareness,
  //   back: 'emergency_contraception',
  //   next: 'main_nav'
  // },
  how_often: {
    component: HowOften,
    additionalClasses: 'icon_array_page',
    back: 'main_nav',
    next: 'main_nav',
  },
  fertility_future: {
    component: FertilityFuture,
    back: 'main_nav',
    next: 'main_nav',
  },
  survey1_mbc: {
    component: Survey1MBC,
    hideNavigation: true,
    additionalClasses: 'survey_page',
    navLinks: [
      'main_nav',
      'survey2_mbc',
    ]
  },
  survey2_mbc: {
    component: Survey2MBC,
    hideNavigation: true,
    additionalClasses: 'survey_page',
    navLinks: [
      'survey1_mbc',
      'survey3_mbc',
    ]
  },
  survey3_mbc: {
    component: Survey3MBC,
    hideNavigation: true,
    additionalClasses: 'survey_page',
    navLinks: [
      'survey2_mbc',
      'survey4_mbc',
    ]
  },
  survey4_mbc: {
    component: Survey4MBC,
    hideNavigation: true,
    additionalClasses: 'survey_page',
    navLinks: [
      'survey3_mbc',
      'survey5_mbc',
    ]
  },
  survey5_mbc: {
    component: Survey5MBC,
    hideNavigation: true,
    additionalClasses: 'survey_page',
    navLinks: [
      'survey4_mbc',
      'recommendations',
    ]
  },
  compare_methods: {
    component: CompareMethods,
    hideNavigation: true,
    navLinks: ['recommendations']
  },
  summary: {
    component: Summary,
    hideNavigation: true,
  },
  secret_developer_page: {
    component: SecretDeveloperPage,
    hideNavigation: true,
  },
  'secret-developer-page': {
    component: SecretDeveloperPage,
    hideNavigation: true,
  }
};


const myPathRoutes = {
  ...sharedRoutes,
  splash: {
    component: SplashMP,
    hideNavigation: true,
  },
  intro_mp: {
    component: IntroMP,
    hideNavigation: true,
  },
  main_nav_mp: {
    component: MainMP,
    navLinks: [
      'survey_mp',
      'fertility',
      'health_before_pregnancy',
      'current_methods',
      'summary'
    ],
  },
  survey_mp: {
    component: SurveyMP,
    additionalClasses: 'survey_page',
    hideNavigation: true,
    navLinks: [
      'main_nav_mp',
      'survey2_mp',
    ]
  },
  current_methods: {
    component: CurrentMethods,
    hideNavigation: true,
    back: 'main_nav_mp',
    next: 'survey2_mp',
    navLinks: [
      'main_nav_mp',
      'current_methods_survey',
    ],
  },
  fertility: {
    component: FertilityMenu,
    back: 'main_nav_mp',
    next: 'main_nav_mp',
  },
  health_before_pregnancy: {
    component: HealthPregnancyMenu,
    back: 'main_nav_mp',
    next: 'health_before_pregnancy_topics'
  },
  health_before_pregnancy_topics: {
    component: HealthPregnancyToipcs,
    back: 'health_before_pregnancy',
    next: 'main_nav_mp'
  },
  current_methods_survey: {
    component: CurrentMethodsSurvey,
    additionalClasses: 'survey_page',
    hideNavigation: true,
    navLinks: [
      'current_methods',
      'main_nav',
    ],
  },
  main_nav: {
    component: MainMBC,
    navLinks: [
      'effectiveness',
      'how_to_women',
      'how_often',
      'side_effects',
      'fertility_future',
      'survey1_mbc'
    ]
  },
  recommendations: {
    component: Recommendations,
    hideNavigation: true,
    navLinks: [
      'compare_methods',
      'main_nav_mp',
    ]
  },
  side_effects: {
    component: SideEffects,
    additionalClasses: 'how_to_page',
    hideNavigation: true,
    navLinks: [
      'main_nav',
      'side_effects_per_method',
    ]
  },
  emergency_contraception: {
    component: EmergencyContraception,
    back: 'how_to_men',
    next: 'fertility_awareness',
  },
  fertility_awareness: {
    component: FertilityAwareness,
    back: 'emergency_contraception',
    next: 'main_nav'
  },
};

const myBCRoutes = {
  ...sharedRoutes,
  splash: {
    component: HelloBeautiful,
    hideNavigation: true,
    navLinks: [
      '/en/intro',
      '/es/intro',
    ]
  },
  intro: {
    component: BigDeal,
    hideNavigation: true,
  },
  main_nav: {
    component: MainMBC,
    navLinks: [
      'effectiveness',
      'how_to_women',
      'how_often',
      'side_effect_preface',
      'fertility_future',
      'survey1_mbc'
    ]
  },
  recommendations: {
    component: Recommendations,
    hideNavigation: true,
    navLinks: [
      'compare_methods',
      'got_questions',
    ]
  },
  side_effects: {
    component: SideEffects,
    additionalClasses: 'how_to_page',
    hideNavigation: true,
    navLinks: [
      'side_effect_preface',
      'side_effects_per_method',
    ]
  },
  got_questions: {
    component: GotQuestions,
    hideNavigation: true,
    navLinks: [
      '',
      'summary'
    ]
  },
  emergency_contraception: {
    component: EmergencyContraception,
    back: 'how_to_men',
    next: 'main_nav',
  },
};

const peripartumRoutes = {
  ...sharedRoutes,
  splash: {
    component: HelloBeautiful,
    hideNavigation: true,
    navLinks: [
      '/en/intro',
      '/es/intro',
    ]
  },
  intro: {
    component: TakeAMoment,
    hideNavigation: true,
  },
  main_nav: {
    component: MainMBC,
    navLinks: [
      'sex_after_baby',
      'effectiveness',
      'how_to_women',
      'how_often',
      'side_effect_preface',
      'fertility_future',
      'considerations'
    ]
  },
  recommendations: {
    component: Recommendations,
    hideNavigation: true,
    navLinks: [
      'compare_methods',
      'got_questions',
    ]
  },
  sex_after_baby: {
    component: SexAfterBaby,
    next: 'pregnant_again_first',
    back: 'main_nav'
  },
  pregnant_again_first: {
    component: PregnantAgainFirst,
    next: 'pregnant_again_second',
    back: 'sex_after_baby'
  },
  pregnant_again_second: {
    component: PregnantAgainSecond,
    next: 'breastfeeding',
    back: 'pregnant_again_first'
  },
  breastfeeding: {
    component: Breastfeeding,
    next: 'important_information',
    back: 'pregnant_again_second'
  },
  important_information: {
    component: ImportantInfo,
    next: 'main_nav',
    back: 'breastfeeding'
  },
  considerations: {
    component: Considerations,
    back: 'main_nav',
    next: 'survey1_mbc'
  },
  side_effects: {
    component: SideEffects,
    additionalClasses: 'how_to_page',
    hideNavigation: true,
    navLinks: [
      'side_effect_preface',
      'side_effects_per_method',
    ]
  },
  got_questions: {
    component: GotQuestions,
    hideNavigation: true,
    navLinks: [
      '',
      'summary'
    ]
  },
  emergency_contraception: {
    component: EmergencyContraception,
    back: 'how_to_men',
    next: 'main_nav',
  },
};

const superDevModeRoutes = { ...myPathRoutes, ...peripartumRoutes };

export {
  myPathRoutes,
  myBCRoutes,
  peripartumRoutes,
  superDevModeRoutes,
};
