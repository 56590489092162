// @flow

import React from 'react';

const greenCheck = require('./green-check.svg');

type Props = {
  checked: boolean,
  uncheckedAsNull: boolean,
};

const CheckMarkOrHiddenSpan = ({ checked, uncheckedAsNull }: Props): React.Element<'img'> | React.Element<'span'> => {
  if (checked) {
    return (
      <>
        <span className="screen-reader-only">selected</span>
        <img src={greenCheck} aria-hidden alt="" />
      </>
    );
  }
  if (uncheckedAsNull) return (null);
  return <span className="hidden">Not selected</span>;
};

export default CheckMarkOrHiddenSpan;
