// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import MenuItem from '../../shared/menuItem/MenuItem';
import EmailModal from '../../shared/emailModal/EmailModal';
import afterBabyActions from '../../../actions/afterBabyActions';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import { validEmail } from '../../../lib/utils';
import type { PageProps } from '../../../lib/types';
import './ImportantInformation.scss';

type ImportantInformationProps = PageProps & {
  afterBabyTopics: {},
  setModalVisible: () => {},
  setModalVInvisible: () => {},
};

type ImportantInformationState = {
  menuItemModalVisible: null | string,
  isEmailModalOn: boolean,
  isSendEmailButtonDisabled: boolean,
  isShowingEmailSuccessMessage: boolean,
  isShowingEmailErrorMessage: boolean,
  email: string,
};

class ImportantInformation extends React.Component<ImportantInformationProps, ImportantInformationState> {
  _modalHeading: ?HTMLHeadingElement;
  _modalWarning: ?HTMLParagraphElement;

  constructor(props: ImportantInformationProps) {
    super(props);
    this.state = {
      menuItemModalVisible: null,
      isEmailModalOn: false,
      isSendEmailButtonDisabled: true,
      isShowingEmailSuccessMessage: false,
      isShowingEmailErrorMessage: false,
      email: '',
    };
  }

  modalHeadingRef = (c: ?HTMLHeadingElement) => { this._modalHeading = c; }
  modalWarningRef = (c: ?HTMLParagraphElement) => { this._modalWarning = c; }
  pdfButtonRef = (c: ?HTMLButtonElement) => { this._pdfButton = c; }

  // menuItem handles focusing already
  showModal = (item: string) => {
    const { onModalShow } = this.props;
    if (onModalShow) { onModalShow(item); }
    this.setState({ menuItemModalVisible: item });
  };

  hideModal = () => {
    this.setState({ menuItemModalVisible: null });
  }

  emailModalClickHandler = () => {
    const { isEmailModalOn } = this.state;
    const { setModalInvisible, setModalVisible } = this.props;

    if (isEmailModalOn) {
      setModalInvisible();
      this.setState({
        isEmailModalOn: false,
        isShowingEmailSuccessMessage: false,
        isShowingEmailErrorMessage: false
      }, () => { this._pdfButton.focus(); });
    } else {
      setModalVisible();
      this.setState({
        isEmailModalOn: true,
        isShowingEmailSuccessMessage: false,
        isShowingEmailErrorMessage: false
      }, () => { this._modalHeading.focus(); });
    }
  };

  emailInputHandler = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    this.setState({
      email: event.target.value,
      isSendEmailButtonDisabled: !validEmail(event.target.value)
    });
  };

  emailPdf = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const { locale } = this.props;
    const { email } = this.state;
    this.setState({ isSendEmailButtonDisabled: true });

    fetch('/emailer/peripartum_pdf', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({ email, locale }),
    })
      .then((response: string): Object => response.json())
      .then(() => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailSuccessMessage: true
        },
        () => { this._modalWarning.focus(); });
      })
      .catch((err: string) => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailErrorMessage: true
        },
        () => { this._modalWarning.focus(); });

        console.log(err);
      });
  };

  render() {
    const { content, locale, afterBabyTopics, isAnyModalVisible } = this.props;
    const { menuItemModalVisible, isShowingEmailErrorMessage, isShowingEmailSuccessMessage, isEmailModalOn, email, isSendEmailButtonDisabled } = this.state;

    const menuItemProps = (item: string): {} => ({
      question: content[`${item}_topic`],
      modalCopy: content[`${item}_modal_copy`],
      modalVisible: menuItemModalVisible === item,
      ariaHidden: isAnyModalVisible,
      onModalShow: () => { this.showModal(item); },
      onModalHide: this.hideModal,
      visited: !!afterBabyTopics[item],
      buttonText: content.buttonText,
      content,
      locale,
    });

    return (
      <>
        <Headings ariaHidden={isAnyModalVisible} isAutoFocusing str={content.headings} />
        <MarkdownBlock ariaHidden={isAnyModalVisible} str={content.paragraphs} />
        <div className="menu-container">
          <MenuItem {...menuItemProps('can_start')} className="first" />
          <MenuItem {...menuItemProps('blood_clot')} className="third" />
          <MenuItem {...menuItemProps('breastfeeding')} className="fourth" />
        </div>
        <MarkdownBlock ariaHidden={isAnyModalVisible} str={content.footer_text} />
        <div className="pdf-button-container">
          <button
            type="button"
            className="pdf-button"
            onClick={this.emailModalClickHandler}
            disabled={isAnyModalVisible}
            aria-hidden={isAnyModalVisible}
            ref={this.pdfButtonRef}
            tabIndex="0"
          >
            <MarkdownBlock str={content.pdfButtonText} />
          </button>
        </div>
        <EmailModal
          modalHeadingRef={this.modalHeadingRef}
          modalWarningRef={this.modalWarningRef}
          closeModalHandler={this.emailModalClickHandler}
          isModalOn={isEmailModalOn}
          submitHandler={this.emailPdf}
          emailInputHandler={this.emailInputHandler}
          email={email}
          isShowingEmailErrorMessage={isShowingEmailErrorMessage}
          isShowingEmailSuccessMessage={isShowingEmailSuccessMessage}
          emailModalText={content.emailModal}
          isSendEmailButtonDisabled={isSendEmailButtonDisabled}
          closeBox={content.closeBox}
        />
      </>
    );
  }
}

const mapStateToProps = ({ afterBabyTopics }: StateType): State => ({ afterBabyTopics });

const mapDispatchToProps = { ...afterBabyActions, ...isAnyModalVisibleActions };


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportantInformation);
