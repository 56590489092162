// @flow

import React, { useRef, createRef, useEffect } from 'react';
import { QuestionText } from '../textComponents/TextComponents';

type SingleRadioSurveyQuestionProps = CommonSurveyQuestionProps & {
  texts: Array<string>,
  ariaHidden: boolean,
  choices: Array<string>,
  clickHandler: (number) => void,
  isVertical: boolean,
  selectedChoiceIndex: null | number,
};

const SingleRadioSurveyQuestion = ({
  texts,
  choices,
  ariaHidden,
  questionIndex,
  selectedChoiceIndex,
  clickHandler,
  isVertical = false,
}: SingleRadioSurveyQuestionProps): React.Element<'div'> => {
  const refs = useRef(new Array(choices.length).fill(0).map((): {} => createRef()));

  useEffect(() => {
    if (selectedChoiceIndex !== null && selectedChoiceIndex > -1) refs.current[selectedChoiceIndex].current.focus();
  }, [selectedChoiceIndex]);

  return (
    <form aria-hidden={ariaHidden}>
      <div className={`survey-question-container ${isVertical ? 'vertical' : ''}`} role="group" aria-labelledby={`question-${questionIndex}`}>
        <QuestionText textArr={texts} id={`question-${questionIndex}`} />
        <div className="survey-choices-container">
          {
            choices.map((choice: string, choiceIndex: number): React.Element<'label'> => {
              const key = `question-${questionIndex}-option-${choiceIndex}`;

              const keyPressHandler = (evt: React.SyntheticEvent) => {
                const keyNum = (evt.which) ? evt.which : evt.keyCode;

                switch (keyNum) {
                  case 40: // down
                    if (choiceIndex === choices.length - 1) clickHandler(0);
                    else clickHandler(choiceIndex + 1);
                    break;
                  case 39: // right
                    if (choiceIndex === choices.length - 1) clickHandler(0);
                    else clickHandler(choiceIndex + 1);
                    break;
                  case 38: // up
                    if (choiceIndex === 0) clickHandler(choices.length - 1);
                    else clickHandler(choiceIndex - 1);
                    break;
                  case 37: // left
                    if (choiceIndex === 0) clickHandler(choices.length - 1);
                    else clickHandler(choiceIndex - 1);
                    break;
                  case 32: // space
                    clickHandler(choiceIndex);
                    break;
                  case 13: // enter
                    clickHandler(choiceIndex);
                    break;
                  default:
                    break;
                }
              };

              const isSelected = selectedChoiceIndex === choiceIndex;

              return (
                <button
                  type="button"
                  role="radio"
                  className={`survey-choice ${isSelected ? 'active' : ''}`}
                  aria-checked={isSelected}
                  name={`question-${questionIndex}`}
                  key={key}
                  tabIndex={ariaHidden ? null : '0'}
                  onClick={() => { clickHandler(choiceIndex); }}
                  onKeyDown={keyPressHandler}
                  ref={refs.current[choiceIndex]}
                >
                  {choice}
                </button>
              );
            })
          }
        </div>
      </div>
    </form>
  );
}

export default SingleRadioSurveyQuestion;
