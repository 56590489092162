// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import Topic from '../../shared/topic/Topic';
import type { PageProps } from '../../../lib/types';
import './HealthPregnancyTopics.scss';

class HealthPregnancyTopics extends React.PureComponent<PageProps> {
  componentWillUnmount() { this.props.hideInstructions(); }

  render() {
    const { content, isAnyModalVisible, hideInstructions } = this.props;

    return (
      <div className="HealthPregnancyMenu">
        <Headings
          isAutoFocusing
          str={content.headings}
          ariaHidden={isAnyModalVisible}
        />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="topics" role="presentation" onClick={hideInstructions}>
          {
            content.topics.map((topic: string, i: number): React.Element<*> => (
              <Topic topic={topic} key={topic} ariaHidden={isAnyModalVisible} isFirst={i === 0} />
            ))
          }
        </div>
        <MarkdownBlock str={content.bottomText} ariaHidden={isAnyModalVisible} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  hideInstructions: (): Action => ({ type: 'HIDE_TOPIC_MODAL' })
};

export default connect(null, mapDispatchToProps)(HealthPregnancyTopics);
