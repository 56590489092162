// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SingleRadioSurveyQuestion from '../../shared/survey/SingleRadioSurveyQuestion';
import { isQuestionFullyAnsweredFuncMP } from '../../../lib/utils';
import NavButton from '../../shared/nav/NavButton';
import Note from '../../shared/note/Note';
import SurveyActions from '../../../actions/SurveyActions';
import type { SurveyMPProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';

type SurveyMPState = {
  pageNum: number,
};

const questionRanges = [[0, 1, 2], [3, 4], [5, 6]];
const choppedQuetionRanges = [[0, 1, 2], [4], [5, 6]];
const lastPageNum = questionRanges.length - 1;

class SurveyMP extends React.Component<SurveyMPProps, SurveyMPState> {
  _heading1: ?HTMLHeadingElement;

  state = { pageNum: 0 }

  componentDidMount() { this._heading1.focus({ preventScroll: false }); }

  externalHeading1Ref = (c: ?HTMLHeadingElement) => { this._heading1 = c; }

  fakeBackClickHandler = () => {
    const { pageNum } = this.state;
    const { _heading1 } = this;
    this.setState({ pageNum: pageNum - 1 }, () => {
      _heading1.focus({ preventScroll: false });
    });
  }

  fakeNextClickHandler = () => {
    const { pageNum } = this.state;
    const { _heading1 } = this;
    this.setState({ pageNum: pageNum + 1 }, () => {
      _heading1.focus({ preventScroll: false });
    });
  }

  render() {
    const { pageNum } = this.state;
    const {
      locale, navLinks, surveyStateMP, answerSurveySingleMP, isAnyModalVisible,
      content: { headings, paragraphs, notes, navigation, mypathSurveyQuestions, hiddenText }
    } = this.props;

    const filteredQuestionRanges = surveyStateMP[2] === 3 ? choppedQuetionRanges : questionRanges;
    const isNextButtonHidden = !filteredQuestionRanges[pageNum].every((index: number): boolean => (surveyStateMP[index] !== null));

    // conditionally rendering
    const hiddenClass = isNextButtonHidden ? 'no-display' : '';
    const backNavigation = pageNum === 0 ? (
      <NavButton str={navigation.backText} to={`/${locale}/${navLinks[0]}`} left isHidden={isAnyModalVisible} />
    ) : (
      <button
        onClick={this.fakeBackClickHandler}
        disabled={isAnyModalVisible}
        aria-hidden={isAnyModalVisible}
        type="button"
        className="nav-button left"
      >
        {`« ${navigation.backText} `}
      </button>
    );

    const nextNavigation = pageNum === lastPageNum || surveyStateMP[2] === 0 ? (
      <NavButton str={navigation.nextText} to={`/${locale}/${navLinks[0]}`} isHidden={isNextButtonHidden || isAnyModalVisible} />
    ) : (
      <button
        onClick={this.fakeNextClickHandler}
        disabled={isNextButtonHidden || isAnyModalVisible}
        aria-hidden={isNextButtonHidden || isAnyModalVisible}
        type="button"
        className={`nav-button ${hiddenClass}`}
      >
        {`${navigation.nextText} »`}
      </button>
    );

    return (
      <>
        <Headings str={headings} externalHeading1Ref={this.externalHeading1Ref} ariaHidden={isAnyModalVisible} />
        {
          pageNum === 0 && (
            <>
              <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
              { hiddenText ? <h2 className="screen-reader-only" aria-hidden={isAnyModalVisible}>{hiddenText}</h2> : null }
              <div className="page-1 survey-questions-container">
                <SingleRadioSurveyQuestion
                  questionIndex={0}
                  texts={mypathSurveyQuestions[0].texts}
                  choices={mypathSurveyQuestions[0].choices}
                  ariaHidden={isAnyModalVisible}
                  selectedChoiceIndex={surveyStateMP[0]}
                  clickHandler={(choiceIndex: number) => {
                    answerSurveySingleMP(0, choiceIndex);
                  }}
                />
                {
                  isQuestionFullyAnsweredFuncMP(surveyStateMP[0]) && (
                    <SingleRadioSurveyQuestion
                      questionIndex={1}
                      texts={surveyStateMP[0] === 0 ? mypathSurveyQuestions[1].texts2 : mypathSurveyQuestions[1].texts1}
                      choices={mypathSurveyQuestions[1].choices}
                      ariaHidden={isAnyModalVisible}
                      selectedChoiceIndex={surveyStateMP[1]}
                      clickHandler={(choiceIndex: number) => {
                        answerSurveySingleMP(1, choiceIndex);
                      }}
                    />
                  )
                }
                {
                  isQuestionFullyAnsweredFuncMP(surveyStateMP[1]) && (
                    <SingleRadioSurveyQuestion
                      questionIndex={2}
                      texts={mypathSurveyQuestions[2].texts}
                      choices={mypathSurveyQuestions[2].choices}
                      ariaHidden={isAnyModalVisible}
                      selectedChoiceIndex={surveyStateMP[2]}
                      isVertical
                      clickHandler={(choiceIndex: number) => {
                        answerSurveySingleMP(2, choiceIndex);
                      }}
                    />
                  )
                }
              </div>
            </>
          )
        }
        {
          pageNum === 1 && (
            <div className="page-2 survey-questions-container">
              {
                surveyStateMP[2] !== 3 && (
                  <SingleRadioSurveyQuestion
                    questionIndex={3}
                    texts={mypathSurveyQuestions[3].texts}
                    choices={mypathSurveyQuestions[3].choices}
                    ariaHidden={isAnyModalVisible}
                    selectedChoiceIndex={surveyStateMP[3]}
                    isVertical
                    clickHandler={(choiceIndex: number) => {
                      answerSurveySingleMP(3, choiceIndex);
                    }}
                  />
                )
              }
              {
                (isQuestionFullyAnsweredFuncMP(surveyStateMP[3]) || surveyStateMP[2] === 3) && (
                  <SingleRadioSurveyQuestion
                    questionIndex={4}
                    texts={mypathSurveyQuestions[4].texts}
                    choices={mypathSurveyQuestions[4].choices}
                    ariaHidden={isAnyModalVisible}
                    selectedChoiceIndex={surveyStateMP[4]}
                    isVertical
                    clickHandler={(choiceIndex: number) => {
                      answerSurveySingleMP(4, choiceIndex);
                    }}
                  />
                )
              }
            </div>
          )
        }
        {
          pageNum === 2 && (
            <div className="page-3 survey-questions-container">
              <SingleRadioSurveyQuestion
                questionIndex={5}
                texts={mypathSurveyQuestions[5].texts}
                choices={mypathSurveyQuestions[5].choices}
                ariaHidden={isAnyModalVisible}
                selectedChoiceIndex={surveyStateMP[5]}
                isVertical
                clickHandler={(choiceIndex: number) => {
                  answerSurveySingleMP(5, choiceIndex);
                }}
              />
              {
                (isQuestionFullyAnsweredFuncMP(surveyStateMP[5])) && (
                  <SingleRadioSurveyQuestion
                    questionIndex={6}
                    texts={mypathSurveyQuestions[6].texts}
                    choices={mypathSurveyQuestions[6].choices}
                    ariaHidden={isAnyModalVisible}
                    selectedChoiceIndex={surveyStateMP[6]}
                    isVertical
                    clickHandler={(choiceIndex: number) => {
                      answerSurveySingleMP(6, choiceIndex);
                    }}
                  />
                )
              }
            </div>
          )
        }

        <div className="nav-buttons-container">
          {backNavigation}
          <Note content={notes} />
          {nextNavigation}
        </div>
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<State> = ({ surveyStateMP }: State): Object => ({ surveyStateMP });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyMP);
