// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from './textComponents/TextComponents';
import type { PageProps } from '../../lib/types';
import NavContainer from './nav/NavContainer';
import ModalWithOverlay from './modal/ModalWithOverlay';
import isAnyModalVisibleActions from '../../actions/isAnyModalVisibleActions';

type IntroPageState = {
  isModalOn: boolean,
};

type IntroPageProps = PageProps & {
  children?: React.Node,
  nextPage: string,
  navInside?: boolean,
};


class IntroPage extends React.Component<IntroPageProps, IntroPageState> {
  state = { isModalOn: true };

  constructor(props: IntroPageProps) {
    super(props);
    this.modalCopyRef = React.createRef();
    this.headingRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.modalCopyRef.current) this.modalCopyRef.current.focus();
    this.props.setModalVisible();
  };

  modalClickHandler = () => {
    this.props.setModalInvisible();
    this.setState({ isModalOn: false }, () => {
      this.headingRef.current.focus();
    });
  };

  render() {
    const { content, locale, nextPage, children, navInside, isAnyModalVisible } = this.props;
    const { isModalOn } = this.state;
    const overflowHandleStyle = isModalOn ? { overflowY: 'hidden' } : {};

    const navigation = (
      <NavContainer
        nextPage={nextPage}
        locale={locale}
        isHidingNextButton={isModalOn}
        nextText={content.continue}
        hideNotes
        notesContent={content.notes}
        navigationContent={content.navigation}
      />
    );

    return (
      <>
        <div
          className="aria"
          aria-hidden={isModalOn}
          style={overflowHandleStyle}
        >
          <div className="flex-group">
            <Headings
              str={content.headings}
              externalHeading1Ref={this.headingRef}
              ariaHidden={isAnyModalVisible}
            />
            <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
            {navInside && navigation}
          </div>
        </div>

        <ModalWithOverlay
          id="disclaimer"
          clickHandler={this.modalClickHandler}
          isModalOn={isModalOn}
          closeBox={content.closeBox}
        >
          <div className="modal-content">
            <h1
              id="disclaimer-modal-title"
              className="focus-start"
              tabIndex="-1"
              ref={this.modalCopyRef}
            >
              {content.disclaimer}
            </h1>
            <MarkdownBlock str={content.legalText} />
          </div>
        </ModalWithOverlay>

        {children}

        {!navInside && navigation}
      </>
    );
  }
}

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(null, mapDispatchToProps)(IntroPage);
